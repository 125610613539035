@import "./mediaquery";
@import "./variables.scss";

.tasks {
  position: relative;
  hr {
    color: #60606030 !important;
    margin: 10px 0 10px 0;
  }

  .welcome {
    margin: 2em 1em;
    display: grid;
    grid-template-columns: 1fr auto;
    gap: 2em;
    border-bottom: 1px solid #60606030;
    padding-bottom: 1em;

    h1 {
      font-size: x-large;
      font-weight: 560;
      margin-bottom: 10px;
    }

    p {
      font-size: large;
    }

    .search {
      display: grid;
      grid-template-columns: auto 1fr auto;
      gap: 10px;

      .select {
        select {
          margin: auto;
          background-color: #60606020;
          padding: 1em;
          border: none;
          outline: none;
          border-radius: 44px;
          height: 44px;
        }
      }
      input {
        background-color: #60606020;
        padding: 1em;
        border: none;
        outline: none;
        border-radius: 44px;
        height: 44px;
      }
      i {
        padding: 1em;
        color: orange;
        height: 44px;
        margin-left: -54px;
      }
      input:focus {
        outline: 1px solid orange;
      }
    }
  }

  .intro {
    padding: 1em;

    h4 {
      margin-top: 10px;
      font-size: x-large;
      font-weight: 600;

      span {
        color: orange;
        margin-right: 10px;
      }
    }
  }

  .summary {
    display: grid;
    grid-template-columns: 1fr 1fr;
    padding: 1em;
    gap: 10px;

    .inner {
      display: grid;
      grid-template-columns: auto 1fr;
      gap: 1em;
      background-color: white;
    }

    .key {
      display: grid;
      grid-template-rows: repeat(4, 1fr);

      border-radius: 4px;
      width: 100%;

      p {
        margin: auto 10px auto 10px;
        width: 100%;
        display: block;
        font-size: large !important;
        text-align: right;
      }

      .tt {
        background-color: $primary;
        color: white;
        border: 1px solid white;
        text-align: center;
        padding: 10px;
        display: grid;
        grid-template-columns: auto 1fr;

        * {
          display: block;
          margin: auto;
        }
      }
      .tc {
        background-color: $secondary;
        color: white;
        border: 1px solid white;
        text-align: center;
        padding: 10px;
        display: grid;
        grid-template-columns: auto 1fr;
        * {
          display: block;
          margin: auto;
        }
      }
      .tf {
        background-color: green;
        color: white;
        border: 1px solid white;
        text-align: center;
        padding: 10px;
        display: grid;
        grid-template-columns: auto 1fr;
        * {
          display: block;
          margin: auto;
        }
      }
      .to {
        background-color: $accent;
        color: white;
        text-align: center;
        border: 1px solid white;
        padding: 10px;
        display: grid;
        grid-template-columns: auto 1fr;
        * {
          display: block;
          margin: auto;
        }
      }
    }

    .chart {
      background-color: white;
      border-radius: 4px;
      display: grid;
      grid-template-columns: 1fr auto;

      h4 {
        writing-mode: vertical-lr;
        text-orientation: mixed;
        padding: 1em 2em 1em 2em;
        text-align: center;
      }
    }
  }

  .selector {
    margin: 0 1em;
    border-bottom: 1px solid #60606030;

    .cont {
      display: grid;
      grid-template-columns: repeat(2, auto);
      gap: 3em;
      width: fit-content;

      h3 {
        cursor: pointer;
        font-weight: 560;
        width: fit-content;
        font-size: medium;
        padding-bottom: 10px;
      }
      h3:hover {
        color: $primary;
      }
      .active {
        color: $primary;
        border-bottom: 3px solid $primary;
      }
    }
  }

  .tcontent {
    margin: 1em;

    .accordion-body {
      padding: 10px;
      background-color: white;
      border-radius: 8px;
      border: 1px solid #60606010;
      box-shadow: 2px 4px 30px #60606030;
      margin-bottom: 1em;
      display: grid;
      grid-template-columns: auto 1fr;
      gap: 2em;

      .date {
        padding: 10px;
        border-radius: 5px;
        text-align: center;
        color: white;

        h1 {
          font-size: x-large;
        }

        p {
          font-size: medium;
        }
      }

      .bdy {
        h4 {
          font-size: medium;
          font-weight: 560;
          margin-bottom: 5px;
        }
        .list {
          display: grid;
          grid-template-columns: repeat(4, 1fr);
          gap: 10px;

          :nth-child(3) {
            border-right: none;
          }

          p {
            font-weight: 560;
            font-size: medium;
            color: gray;
            font-family: "Inter";
            text-align: left;
            border-right: 2px solid #60606030;
            padding: 10px 0 10px 0;
          }
          .progress {
            p {
              border-right: none;
            }

            .bar {
              height: 15px;
              border-radius: 5px;
              background: #60606030;
              margin-top: 10px;
              overflow: hidden;
              width: 100%;
              position: relative;

              .prg {
                position: absolute;
                top: 0;
                left: 0;
                bottom: 0;
                right: 100%;
                height: 100%;
                width: 0%;
                background-color: $primary;
              }
            }
          }
        }
      }
    }
  }

  .popup {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    height: 100%;
    width: 100%;
    background-color: rgba($color: #000000, $alpha: 0.5);
    display: flex;
    align-items: center;
    z-index: 999999;
    animation: zoom 1s ease;

    .pcontainer {
      background-color: white;
      border-radius: 4px;
      margin: 0 auto 0 auto;
      color: #000000;
      max-width: 70vw;
      max-height: 90vh;
      min-width: 50%;
      height: fit-content;
      overflow-y: auto;
      width: fit-content;
      position: relative;

      .tbar {
        background-color: $accent;
        padding: 16px !important;

        h3 {
          text-align: center;
          font-size: medium;
          color: white;
        }

        .fa {
          position: absolute;
          top: 10px;
          right: 10px;
          color: white;
          height: fit-content;
          margin: auto;
          padding: 5px;
        }
        .fa:hover {
          color: $accent;
        }
      }

      .wcontent {
        padding: 1em;
        position: relative;

        h5 {
          color: $primary;
          cursor: pointer;
          font-size: small !important;
        }

        h6 {
          text-align: center;
          color: $accent;
          margin-top: 5px;
          font-weight: 500;
        }

        .u_list {
          box-shadow: 1px 1px 3px #60606010;
          border: 1px solid #60606010;
          border-radius: 2px;
          margin: 6px 0 6px 0;
          padding: 10px;
          position: relative;
          cursor: pointer;

          .updateprp {
            position: absolute;
            left: 0;
            right: 0;
            top: 0;
            bottom: 0;
            display: flex;
            align-items: center;
            z-index: 999;
            background-color: #60606010;

            .up_wrapper {
              margin: auto;
              background-color: white;
              min-width: 250px;
              width: fit-content;
              padding: 1em;
              box-shadow: 1px 1px 3px #60606010;
              border: 1px solid #60606010;
              border-radius: 5px;
              position: relative;

              .fa-times {
                color: $accent !important;
                cursor: pointer;
              }

              h6 {
                margin: 5px 0 5px 0;
                min-height: 16px;
                color: $accent;
                text-align: center;
                font-size: small;
                font-weight: 100;
              }

              .usrinput {
                margin: 10px 0 10px 0;

                h4 {
                  display: block;
                  text-align: left;
                  font-weight: 400;
                  font-size: small;
                  color: #000000 !important;
                }

                input {
                  margin-top: 0px !important;
                  padding: 10px;
                  width: 100%;
                  background-color: $bg1;
                  border-top: none;
                  border-right: none;
                  border-left: none;
                  outline: none;
                  border-bottom: 3px solid rgba($primary, 0.5);
                }
                input:focus {
                  border-bottom: 2px solid $primary;
                }
              }
            }
          }

          .p_check {
            box-shadow: 1px 1px 3px #60606010;
            border: 1px solid #60606010;
            border-radius: 2px;
            margin: 6px 0 6px 0;
            padding: 5px;
            position: relative;
            cursor: pointer;
            display: grid;
            grid-template-columns: auto 1fr;
            gap: 10px;

            div {
              height: 100%;
              width: 24px;
              height: 24px;
              margin: auto !important;
              background-color: red;
              border-radius: 24px;
            }

            p {
              padding: 0 !important;
            }
          }

          .p_check:hover {
            background-color: #fff5b8;
          }

          hr {
            color: #60606010;
            margin-top: 5px;
          }

          .div2equal {
            gap: 1em !important;
          }

          .no {
            position: absolute;
            right: 5px;
            top: 0;
            bottom: 0;
            height: 100%;
            left: auto;
            display: flex;
            align-items: center;

            p {
              color: white;
              font-size: small;
              font-weight: 600;
              text-align: center;
              padding: 5px 10px;
              border-radius: 24px;
              background-color: $accent;
            }
          }

          .fa-times {
            position: absolute;
            right: 10px;
            top: 10px;
            color: $accent;
            cursor: pointer;
          }

          h3,
          h4 {
            font-size: medium;
            font-weight: 500;
            text-align: center;
            color: $primary;
          }

          b {
            font-weight: 500;
          }

          p {
            font-size: small;
            font-weight: 200;
            padding: 3px;
          }
        }

        .div3equal {
          gap: 10px !important;
        }

        .usrinput {
          margin: 10px 0 10px 0;

          h4 {
            display: block;
            margin-bottom: 10px !important;
            font-weight: 400;
            font-size: small;
          }

          input {
            margin-top: 10px;
            padding: 10px;
            width: 100%;
            background-color: $bg;
            border-top: none;
            border-right: none;
            border-left: none;
            outline: none;
            border-bottom: 3px solid rgba($primary, 0.5);
          }
          input:focus {
            border-bottom: 2px solid $primary;
          }
        }

        .search {
          position: relative;

          .search_list {
            position: absolute;
            left: 0;
            right: 0;
            top: 74px;
            padding: 5px;
            border: 1px solid #60606030;
            border-radius: 5px;
            box-shadow: 1px 1px 5px #60606030;
            z-index: 999;
            background-color: white;

            p {
              font-size: x-small;
              padding: 5px;
              cursor: pointer;
              margin-bottom: 3px;
            }
            p:hover {
              color: $secondary;
            }
          }
        }

        .selected_staff {
          box-shadow: 1px 1px 5px #60606030;
          border-radius: 5px;
          width: 100%;
          position: relative;
          padding: 10px;
          margin-top: 10px;

          h3,
          p {
            font-size: small;
          }

          .fa-times {
            position: absolute;
            right: 10px;
            top: 10px;
          }
        }

        button {
          display: block;
          margin: 10px auto 10px auto;
          padding: 4px;
          width: 80%;
          font-size: small;
          background-color: $primary;
          color: white;
          border-top: none;
          border-right: none;
          border-left: none;
          border-bottom: 3px solid rgba($primary, 0.5);
          border-top: 2px solid #60606070;
          cursor: pointer;
        }
        button:hover {
          background-color: rgba($color: $primary, $alpha: 0.8);
        }

        .div {
          box-shadow: 1px 1px 3px #60606010;
          border: 1px solid #60606010;
          border-radius: 2px;
          padding: 10px;
          min-height: 90%;
          height: 100%;
        }

        .prg {
          display: grid;
          grid-template-rows: repeat(5, 1fr);
          gap: 5px;
          height: auto;

          .item {
            display: grid;
            grid-template-columns: 5px 1fr;
            gap: 10px;
            height: 100%;

            p {
              display: block;
              margin: auto;
              width: 100%;
            }

            div {
              height: 100%;
              width: 100%;
              background-color: orange;
            }
          }
        }

        h5 {
          font-size: medium;
          font-weight: 600;
          margin-bottom: 10px;
          line-height: 24px;
          height: 24px;
        }

        h4,
        p {
          margin: 4px 0 4px 0;
          font-size: small;
        }

        h4 {
          font-weight: 600;
        }

        .link {
          color: $primary;
          font-style: italic;
          margin-left: 10px;
          cursor: pointer;
        }
        .link:hover {
          color: $secondary;
        }
      }
    }
  }

  @include maxwidth(mobile) {
    padding: 10px !important;
    .welcome {
      grid-template-columns: 1fr !important;

      h1 {
        font-size: medium;
      }
    }

    .selector {
      h3 {
        font-size: small !important;
      }
    }

    .search {
      display: flex;
      grid-template-columns: 1fr !important;

      input {
        display: inline-block !important;
      }

      .fa {
        display: none !important;
      }
    }

    .accordion-body {
      grid-template-columns: 1fr !important;
      gap: 0 !important;

      .status {
        grid-template-columns: 1fr !important;
      }

      h4 {
        font-size: small !important;
        margin-top: 8px;
      }

      .date {
        display: flex;
        gap: 10px;

        p {
          font-size: medium !important;
          margin: auto !important;
          display: block;
        }
      }

      .list {
        grid-template-columns: 1fr !important;
        gap: 0 !important;
        padding: 0 !important;

        p {
          padding: 0 !important;
          margin-bottom: 3px;
        }
      }
    }
    .summary {
      grid-template-columns: 1fr !important;
      gap: 1em !important;

      .key {
        min-width: auto !important;
      }

      .chart {
        height: auto !important;
      }
    }

    .popup {
      position: fixed !important;

      .div3equal {
        .div {
          margin-bottom: 10px !important;
          min-height: auto !important;
        }
      }

      .pcontainer {
        max-width: 85vw !important;
        width: 100% !important;
        height: 100% !important;
        overflow-y: auto;

        .link {
          word-break: break-all !important;
        }
      }
    }

    h2,
    h3,
    p,
    h4,
    h5,
    label,
    h6 {
      font-size: small !important;
    }

    .div3equal,
    .div2equal {
      grid-template-columns: 1fr !important;
      gap: 1em !important;
    }
  }
  @include maxwidth(tablet) {
    padding: 10px !important;
    .welcome {
      grid-template-columns: 1fr !important;

      h1 {
        font-size: medium;
      }
    }

    .selector {
      h3 {
        font-size: small !important;
      }
    }

    .search {
      display: flex;
      grid-template-columns: 1fr !important;

      input {
        display: inline-block !important;
      }

      .fa {
        display: none !important;
      }
    }

    .accordion-body {
      grid-template-columns: 1fr !important;
      gap: 0 !important;

      .status {
        grid-template-columns: 1fr !important;
      }

      h4 {
        font-size: small !important;
        margin-top: 8px;
      }

      .date {
        display: flex;
        gap: 10px;

        p {
          font-size: medium !important;
          margin: auto !important;
          display: block;
        }
      }

      .list {
        grid-template-columns: 1fr !important;
        gap: 0 !important;
        padding: 0 !important;

        p {
          padding: 0 !important;
          margin-bottom: 3px;
        }
      }
    }
    .summary {
      grid-template-columns: 1fr !important;
      gap: 1em !important;

      .key {
        min-width: auto !important;
      }

      .chart {
        height: auto !important;
      }
    }

    .popup {
      position: fixed !important;
      left: 0;
      top: 0;
      right: 0;
      bottom: 0;
      display: flex;
      align-items: center;

      .div3equal {
        .div {
          margin-bottom: 10px !important;
          min-height: auto !important;
        }
      }

      .pcontainer {
        max-width: 85vw !important;
        width: 100% !important;
        height: 100% !important;
        overflow-y: auto;

        .link {
          word-break: break-all !important;
        }
      }
    }

    h2,
    h3,
    p,
    h4,
    h5,
    label,
    h6 {
      font-size: small !important;
    }

    .div3equal,
    .div2equal {
      grid-template-columns: 1fr !important;
      gap: 1em !important;
    }
  }
}

.popup {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  height: 100%;
  width: 100%;
  background-color: rgba($color: #000000, $alpha: 0.5);
  display: flex;
  align-items: center;
  z-index: 999999;
  animation: zoom 1s ease;

  .pcontainer {
    background-color: white;
    border-radius: 4px;
    margin: 0 auto 0 auto;
    color: #000000;
    max-width: 70vw;
    max-height: 90vh;
    min-width: 50%;
    height: fit-content;
    overflow-y: auto;
    width: fit-content;
    position: relative;

    .tbar {
      background-color: $accent;
      padding: 16px !important;

      h3 {
        text-align: center;
        font-size: medium;
        color: white;
      }

      .fa {
        position: absolute;
        top: 10px;
        right: 10px;
        color: white;
        height: fit-content;
        margin: auto;
        padding: 5px;
      }
      .fa:hover {
        color: $accent;
      }
    }

    .wcontent {
      padding: 1em;
      position: relative;

      h5 {
        color: $primary;
        cursor: pointer;
        font-size: small !important;
      }

      h6 {
        text-align: center;
        color: $accent;
        margin-top: 5px;
        font-weight: 500;
      }

      .u_list {
        box-shadow: 1px 1px 3px #60606010;
        border: 1px solid #60606010;
        border-radius: 2px;
        margin: 6px 0 6px 0;
        padding: 10px;
        position: relative;
        cursor: pointer;

        .updateprp {
          position: absolute;
          left: 0;
          right: 0;
          top: 0;
          bottom: 0;
          display: flex;
          align-items: center;
          z-index: 999;
          background-color: #60606010;

          .up_wrapper {
            margin: auto;
            background-color: white;
            min-width: 250px;
            width: fit-content;
            padding: 1em;
            box-shadow: 1px 1px 3px #60606010;
            border: 1px solid #60606010;
            border-radius: 5px;
            position: relative;

            .fa-times {
              color: $accent !important;
              cursor: pointer;
            }

            h6 {
              margin: 5px 0 5px 0;
              min-height: 16px;
              color: $accent;
              text-align: center;
              font-size: small;
              font-weight: 100;
            }

            .usrinput {
              margin: 10px 0 10px 0;

              h4 {
                display: block;
                text-align: left;
                font-weight: 400;
                font-size: small;
                color: #000000 !important;
              }

              input {
                margin-top: 0px !important;
                padding: 10px;
                width: 100%;
                background-color: $bg1;
                border-top: none;
                border-right: none;
                border-left: none;
                outline: none;
                border-bottom: 3px solid rgba($primary, 0.5);
              }
              input:focus {
                border-bottom: 2px solid $primary;
              }
            }
          }
        }

        .p_check {
          box-shadow: 1px 1px 3px #60606010;
          border: 1px solid #60606010;
          border-radius: 2px;
          margin: 6px 0 6px 0;
          padding: 5px;
          position: relative;
          cursor: pointer;
          display: grid;
          grid-template-columns: auto 1fr;
          gap: 10px;

          div {
            height: 100%;
            width: 24px;
            height: 24px;
            margin: auto !important;
            background-color: red;
            border-radius: 24px;
          }

          p {
            padding: 0 !important;
          }
        }

        .p_check:hover {
          background-color: #fff5b8;
        }

        hr {
          color: #60606010;
          margin-top: 5px;
        }

        .div2equal {
          gap: 1em !important;
        }

        .no {
          position: absolute;
          right: 5px;
          top: 0;
          bottom: 0;
          height: 100%;
          left: auto;
          display: flex;
          align-items: center;

          p {
            color: white;
            font-size: small;
            font-weight: 600;
            text-align: center;
            padding: 5px 10px;
            border-radius: 24px;
            background-color: $accent;
          }
        }

        .fa-times {
          position: absolute;
          right: 10px;
          top: 10px;
          color: $accent;
          cursor: pointer;
        }

        h3,
        h4 {
          font-size: medium;
          font-weight: 500;
          text-align: center;
          color: $primary;
        }

        b {
          font-weight: 500;
        }

        p {
          font-size: small;
          font-weight: 200;
          padding: 3px;
        }
      }

      .div3equal {
        gap: 10px !important;
      }

      .usrinput {
        margin: 10px 0 10px 0;

        h4 {
          display: block;
          margin-bottom: 10px !important;
          font-weight: 400;
          font-size: small;
        }

        input {
          margin-top: 10px;
          padding: 10px;
          width: 100%;
          background-color: $bg;
          border-top: none;
          border-right: none;
          border-left: none;
          outline: none;
          border-bottom: 3px solid rgba($primary, 0.5);
        }
        input:focus {
          border-bottom: 2px solid $primary;
        }
      }

      .search {
        position: relative;

        .search_list {
          position: absolute;
          left: 0;
          right: 0;
          top: 74px;
          padding: 5px;
          border: 1px solid #60606030;
          border-radius: 5px;
          box-shadow: 1px 1px 5px #60606030;
          z-index: 999;
          background-color: white;

          p {
            font-size: x-small;
            padding: 5px;
            cursor: pointer;
            margin-bottom: 3px;
          }
          p:hover {
            color: $secondary;
          }
        }
      }

      .selected_staff {
        box-shadow: 1px 1px 5px #60606030;
        border-radius: 5px;
        width: 100%;
        position: relative;
        padding: 10px;
        margin-top: 10px;

        h3,
        p {
          font-size: small;
        }

        .fa-times {
          position: absolute;
          right: 10px;
          top: 10px;
        }
      }

      button {
        display: block;
        margin: 10px auto 10px auto;
        padding: 4px;
        width: 80%;
        font-size: small;
        background-color: $primary;
        color: white;
        border-top: none;
        border-right: none;
        border-left: none;
        border-bottom: 3px solid rgba($primary, 0.5);
        border-top: 2px solid #60606070;
        cursor: pointer;
      }
      button:hover {
        background-color: rgba($color: $primary, $alpha: 0.8);
      }

      .div {
        box-shadow: 1px 1px 3px #60606010;
        border: 1px solid #60606010;
        border-radius: 2px;
        padding: 10px;
        min-height: 90%;
        height: 100%;
      }

      .prg {
        display: grid;
        grid-template-rows: repeat(5, 1fr);
        gap: 5px;
        height: auto;

        .item {
          display: grid;
          grid-template-columns: 5px 1fr;
          gap: 10px;
          height: 100%;

          p {
            display: block;
            margin: auto;
            width: 100%;
          }

          div {
            height: 100%;
            width: 100%;
            background-color: orange;
          }
        }
      }

      h5 {
        font-size: medium;
        font-weight: 600;
        margin-bottom: 10px;
        line-height: 24px;
        height: 24px;
      }

      h4,
      p {
        margin: 4px 0 4px 0;
        font-size: small;
      }

      h4 {
        font-weight: 600;
      }

      .link {
        color: $primary;
        font-style: italic;
        margin-left: 10px;
        cursor: pointer;
      }
      .link:hover {
        color: $secondary;
      }
    }
  }
}
