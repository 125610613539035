@import "./_variables";
@import "./mediaquery";

.header {
  top: 0;
  left: 0;
  width: 100%;
  z-index: 9999;
  background-color: white;
  box-shadow: 1px 1px 5px #60606030;

  .contacts {
    border-bottom: 1px solid #60606030;
    padding: 10px 0 1em 0;

    .ccontainer {
      display: grid;
      grid-template-columns: 1fr auto auto auto;
      gap: 1em;
      max-width: 85%;
      width: 100%;
      margin: auto;

      div {
        display: grid;
        grid-template-columns: auto 1fr;
        gap: 1em;
        width: fit-content;

        img {
          display: block;
          margin: auto;
          max-height: 44px;
          height: 100%;
          object-fit: contain;
        }
      }

      h1 {
        color: $primary;
        margin: auto;
        font-size: large;
      }

      a {
        text-decoration: none;
        color: gray;
        font-size: small;
        display: block;
        margin: auto;
      }
    }
  }

  .hcontainer {
    max-width: 85%;
    width: 100%;
    margin: auto;
    position: relative;

    .buttons {
      position: absolute;
      left: auto;
      right: 0;
      top: 0;
      bottom: 0;
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      gap: 10px;

      button {
        background-color: orange;
        color: white;
        border: 1px solid orange;
        border-radius: 5px;
        padding: 10px 1em 10px 1em;
        cursor: pointer;
        margin: auto;
        height: fit-content;
      }
      button:hover {
        border: 1px solid orange;
        color: orange;
        background-color: transparent;
      }
    }

    .wrapper {
      width: fit-content;
      margin: auto;
      padding: 1em;
      gap: 1em;
      display: block;
      grid-template-columns: repeat(6, auto);

      a {
        text-decoration: none;
        font-size: small;
        color: $secondary;
        padding: 5px;
        cursor: pointer;
      }
      a:hover {
        color: $primary;
      }

      .active {
        color: $primary;
      }
    }

    .mob {
      display: none;
      margin: auto 0 auto auto;
      padding: 10px 0 10px 0;

      i {
        font-size: large;
        width: fit-content;
        text-align: center;
        color: $primary;
        margin: auto 0 auto auto;
        display: block;
        height: 30px;
        line-height: 30px;
      }
    }

    @include maxwidth(mobile) {
      .right {
        display: none;
      }

      .mob {
        display: block;
        order: 0;
      }
    }
    @include maxwidth(tablet) {
      .right {
        display: none;
      }

      .mob {
        display: block;
        order: 0;
      }
    }
  }

  @include maxwidth(mobile) {
    position: relative !important;
    .contacts {
      .container {
        display: block !important;
      }

      img {
        max-width: 40vw !important;
        width: 100% !important;
      }
      a {
        display: none !important;
      }
    }

    .hcontainer {
      display: grid;
      grid-template-columns: 1fr auto;
      .buttons {
        display: none !important;
      }
      .wrapper {
        display: none !important;
      }
    }
  }
  @include maxwidth(tablet) {
    .contacts {
      .container {
        display: block !important;
      }

      img {
        max-width: 40vw !important;
        width: 100% !important;
      }
      a {
        display: none !important;
      }
    }

    .hcontainer {
      display: grid;
      grid-template-columns: 1fr auto;
      .buttons {
        display: none !important;
      }
    }
  }
}

.headermodal {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: white;
  color: white;
  z-index: 99999;
  display: flex;
  align-items: center;
  height: 100%;
  overflow-y: auto;

  .container {
    max-width: 85%;
    width: 100%;
    height: fit-content;
    margin: auto;
    display: grid;
    grid-template-rows: auto 1fr auto;

    .itemsLogo {
      margin-bottom: 1em !important;
    }

    img {
      max-width: 70vw;
      width: 100%;
      margin: auto;
      display: block;
      object-fit: contain;
    }

    h4 {
      color: blue !important;
      padding: 10px;
      text-align: center;
      margin-bottom: 10px;
      font-size: medium;
    }

    button {
      background-color: orange;
      color: white;
      border: 1px solid orange;
      border-radius: 5px;
      padding: 10px 1em 10px 1em;
      cursor: pointer;
      margin: 1em auto;
      height: fit-content;
      min-width: 50vw;
    }
    button:hover {
      border: 1px solid orange;
      color: orange;
      background-color: transparent;
    }

    .fa-close {
      color: black;
      margin: auto;
      font-size: x-large;
      margin-top: 2em;
    }
  }
}
