@import "./mediaquery";
@import "./variables";

.myhome {
  height: 100%;
  width: 100%;
  padding: 1em;

  .topdivider {
    display: grid;
    grid-template-columns: 2fr 1fr;
    gap: 1em;
    margin-bottom: 1em;

    .disclaimer {
      background-color: white;
      border-radius: 5px;
      box-shadow: 1px 1px 5px #60606020;
      border: 1px solid #60606020;
      background-image: url("../assets/images/warning.png");
      background-repeat: no-repeat;
      background-size: contain;
      background-position: center;
      display: flex;
      align-items: center;

      .disc {
        background-color: rgba($color: white, $alpha: 0.8);
        padding: 1em 2em;
        height: 100%;
      }

      h3 {
        color: $primary;
        text-align: center;
        font-weight: 600;
        margin: auto;
        height: fit-content;
        font-size: x-large;
        padding: 1em 0 10px 0;
      }

      p {
        width: 100%;
        text-align: center;
        font-weight: 200;
        height: fit-content;
        line-height: 1.5;
        font-size: small;
        margin: 10px 0 10px 0;
      }
    }
    .top {
      background-color: #f7e5e9;
      box-shadow: 1px 1px 5px #60606020;
      border: 1px solid #60606020;
      padding: 1em;
      margin-top: 44px;
      border-radius: 5px;
      position: relative;
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      gap: 2em;

      .left {
        display: grid;
        grid-template-rows: 1fr 3fr;
        padding: 2em 1em 1em 1em;
        position: relative;

        h1 {
          position: absolute;
          top: -64px;
          left: -0.9em;
          font-size: x-large;
          color: #fd6976;
        }

        h3 {
          color: #fd6976;
          text-align: center;
          font-weight: 600;
          margin: auto;
          height: fit-content;
          font-size: x-large;
        }

        p {
          width: 100%;
          text-align: center;
          font-weight: 200;
          font-size: small;
          margin: auto;
          height: fit-content;
          line-height: 1.5;
        }
      }

      .right {
        img {
          left: auto;
          right: auto;
          max-height: 250px;
          height: 100%;
          width: 100%;
          object-fit: contain;
          display: block;
          margin-top: -54px;
        }
      }
    }
  }

  .mob {
    display: none;
  }

  .list {
    background-color: white;
    padding: 1em;
    border-radius: 5px;
    position: relative;
  }

  @include maxwidth(mobile) {
    .topdivider {
      display: none !important;
    }
    .mob {
      display: block;

      h1 {
        font-size: large !important;
        color: $primary;
        margin-bottom: 10px;
      }

      h2 {
        font-size: medium !important;
        color: $primary;
        margin-bottom: 10px;
      }
    }
  }
}
